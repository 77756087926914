import React from "react";
import TablasNomina from '../../components/NominasTabla'
const Home = () => {
  return (
    <div>
      <TablasNomina />
    </div>
  );
};

export default Home;
